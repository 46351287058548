import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 595.000000 460.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,460.000000) scale(0.100000,-0.100000)">
        <path d="M1017 3767 c-43 -120 -44 -127 -17 -127 13 0 22 9 26 25 5 22 11 25
54 25 43 0 49 -3 54 -25 5 -18 13 -25 31 -25 14 0 25 3 25 8 0 4 -17 54 -38
112 -36 100 -40 105 -69 108 -30 3 -30 2 -66 -101z m81 14 c16 -52 16 -51 -19
-51 -26 0 -30 3 -24 18 3 9 9 29 12 45 8 38 18 34 31 -12z"/>
<path d="M1220 3756 l0 -116 60 0 c53 0 60 2 60 20 0 16 -7 20 -35 20 l-35 0
-1 80 c-2 108 -1 105 -26 108 -23 3 -23 2 -23 -112z"/>
<path d="M1350 3850 c0 -16 7 -20 35 -20 l35 0 0 -95 c0 -88 1 -95 20 -95 19
0 20 7 20 95 l0 95 35 0 c28 0 35 4 35 20 0 19 -7 20 -90 20 -83 0 -90 -1 -90
-20z"/>
<path d="M1577 3767 c-43 -120 -44 -127 -17 -127 13 0 22 9 26 25 5 22 11 25
54 25 43 0 49 -3 54 -25 5 -18 13 -25 31 -25 14 0 25 3 25 8 0 4 -17 54 -38
112 -36 100 -40 105 -69 108 -30 3 -30 2 -66 -101z m81 14 c16 -52 16 -51 -19
-51 -26 0 -30 3 -24 18 3 9 9 29 12 45 8 38 18 34 31 -12z"/>
<path d="M1792 3854 c-18 -12 -22 -24 -20 -52 3 -31 9 -40 43 -59 49 -27 55
-58 10 -57 -16 0 -38 4 -47 8 -14 6 -18 3 -18 -13 0 -45 85 -56 125 -16 44 43
30 84 -40 116 -46 22 -39 44 13 43 33 -1 42 3 42 17 0 32 -69 40 -108 13z"/>
<path d="M2030 3755 l0 -117 65 4 c55 3 70 8 93 31 23 23 27 36 27 82 0 46 -4
59 -27 82 -23 23 -38 28 -93 31 l-65 4 0 -117z m120 55 c45 -45 13 -130 -50
-130 l-30 0 0 75 0 75 30 0 c17 0 39 -9 50 -20z"/>
<path d="M2277 3767 c-43 -120 -44 -127 -17 -127 13 0 22 9 26 25 5 22 11 25
54 25 43 0 49 -3 54 -25 5 -18 13 -25 31 -25 14 0 25 3 25 8 0 4 -17 54 -38
112 -36 100 -40 105 -69 108 -30 3 -30 2 -66 -101z m92 -24 c1 -8 -11 -13 -30
-13 -33 0 -33 3 -7 70 l10 25 13 -35 c8 -19 14 -41 14 -47z"/>
<path d="M2450 3850 c0 -16 7 -20 35 -20 l35 0 0 -95 c0 -88 1 -95 20 -95 19
0 20 7 20 95 l0 95 35 0 c28 0 35 4 35 20 0 19 -7 20 -90 20 -83 0 -90 -1 -90
-20z"/>
<path d="M2677 3767 c-43 -120 -44 -127 -17 -127 13 0 22 9 26 25 5 22 11 25
54 25 43 0 49 -3 54 -25 5 -18 13 -25 31 -25 14 0 25 3 25 8 0 4 -17 54 -38
112 -36 100 -40 105 -69 108 -30 3 -30 2 -66 -101z m92 -24 c1 -8 -11 -13 -30
-13 -33 0 -33 3 -7 70 l10 25 13 -35 c8 -19 14 -41 14 -47z"/>
<path d="M2972 3854 c-18 -12 -22 -24 -20 -52 3 -31 9 -40 43 -59 49 -27 55
-58 10 -57 -16 0 -38 4 -47 8 -26 12 -24 -30 1 -44 53 -28 131 7 131 59 0 30
-18 51 -65 72 -46 22 -39 44 13 43 33 -1 42 3 42 17 0 32 -69 40 -108 13z"/>
<path d="M3130 3755 l0 -115 65 0 c58 0 65 2 65 20 0 17 -7 20 -45 20 -43 0
-45 1 -45 30 0 28 2 30 40 30 33 0 40 3 40 20 0 17 -7 20 -40 20 -36 0 -40 3
-40 25 0 23 4 25 45 25 38 0 45 3 45 20 0 18 -7 20 -65 20 l-65 0 0 -115z"/>
<path d="M3310 3755 c0 -108 1 -115 20 -115 17 0 20 7 20 45 0 38 3 45 20 45
14 0 27 -14 42 -45 17 -35 28 -45 46 -45 20 0 22 4 16 23 -12 41 -34 77 -47
77 -6 0 -3 6 8 12 41 23 47 66 15 98 -16 16 -33 20 -80 20 l-60 0 0 -115z
m108 51 c5 -22 -13 -36 -45 -36 -19 0 -23 5 -23 31 0 28 2 30 32 27 23 -2 34
-9 36 -22z"/>
<path d="M3500 3856 c0 -8 16 -60 36 -116 36 -100 37 -101 67 -98 28 3 31 8
68 113 21 61 38 111 39 113 0 1 -10 2 -23 2 -20 0 -27 -12 -53 -92 l-30 -93
-31 90 c-24 73 -34 91 -51 93 -14 2 -22 -2 -22 -12z"/>
<path d="M3740 3755 c0 -108 1 -115 20 -115 19 0 20 7 20 115 0 108 -1 115
-20 115 -19 0 -20 -7 -20 -115z"/>
<path d="M3878 3854 c-37 -20 -48 -43 -48 -104 0 -44 5 -56 29 -81 31 -31 83
-39 122 -19 25 14 27 55 2 45 -67 -28 -103 -7 -103 60 0 67 36 88 103 60 13
-5 17 -2 17 14 0 39 -70 54 -122 25z"/>
<path d="M4040 3755 l0 -115 64 0 c46 0 65 4 69 14 8 21 -2 26 -50 26 -41 0
-43 1 -43 30 0 28 2 30 40 30 33 0 40 3 40 20 0 17 -7 20 -40 20 -36 0 -40 3
-40 25 0 23 4 25 45 25 38 0 45 3 45 20 0 18 -7 20 -65 20 l-65 0 0 -115z"/>
<path d="M4232 3854 c-18 -12 -22 -24 -20 -52 3 -31 9 -40 43 -59 49 -27 55
-58 10 -57 -16 0 -38 4 -47 8 -14 6 -18 3 -18 -13 0 -45 85 -56 125 -16 44 43
30 84 -40 116 -46 22 -39 44 13 43 33 -1 42 3 42 17 0 32 -69 40 -108 13z"/>
<path d="M1590 3603 c0 -5 5 -15 10 -23 8 -12 10 -11 10 8 0 12 -4 22 -10 22
-5 0 -10 -3 -10 -7z"/>
<path d="M3258 3303 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1321 2836 c-34 -69 -82 -216 -103 -311 -19 -89 -17 -399 5 -495 26
-120 54 -197 111 -315 150 -305 423 -545 746 -655 110 -38 301 -70 420 -70
189 0 420 58 599 152 156 81 157 88 17 88 -92 0 -113 -3 -178 -29 -417 -167
-874 -91 -1212 200 -260 224 -406 545 -406 890 0 167 25 286 99 472 17 43 31
79 31 81 0 6 -79 56 -89 56 -6 0 -24 -29 -40 -64z"/>
<path d="M2315 2768 c4 -13 23 -60 42 -105 l35 -83 182 -2 c99 -1 202 0 227 1
26 2 50 -2 53 -8 6 -9 -13 -116 -65 -351 -17 -81 -90 -429 -119 -570 -5 -25
-14 -58 -18 -75 -11 -33 -7 -38 -42 55 -12 29 -24 46 -37 48 -17 2 -24 -10
-46 -75 -31 -91 -32 -93 -12 -93 8 0 15 9 15 20 0 17 7 20 40 20 28 0 40 -4
40 -15 0 -7 6 -18 14 -22 12 -7 11 -21 -5 -98 -29 -137 -39 -125 105 -125 87
0 126 4 133 13 5 6 24 80 42 162 17 83 40 188 51 235 10 47 28 130 40 185 12
55 32 145 45 200 14 55 34 146 44 202 35 175 53 247 67 270 13 21 19 21 266
22 184 1 256 5 264 14 11 13 29 83 40 155 l7 42 -707 0 -707 0 6 -22z m269
-1150 c9 -35 8 -38 -14 -38 -11 0 -20 3 -20 8 0 19 13 52 20 52 4 0 10 -10 14
-22z"/>
<path d="M3797 2505 c-72 -25 -117 -87 -117 -160 0 -80 47 -136 155 -185 85
-39 107 -67 92 -121 -14 -53 -99 -64 -185 -24 -30 14 -58 22 -63 19 -5 -3 -9
-25 -9 -49 0 -67 18 -75 165 -75 102 0 124 3 149 20 47 30 76 87 76 148 0 91
-29 127 -156 190 -45 23 -84 48 -88 57 -31 81 51 121 153 75 23 -11 47 -17 52
-14 15 9 10 81 -6 98 -36 35 -149 46 -218 21z"/>
<path d="M1845 2500 c-5 -8 -183 -514 -199 -567 -7 -22 -5 -23 53 -23 l59 0
22 65 22 66 116 -3 116 -3 20 -62 20 -63 63 0 c38 0 63 4 63 11 0 17 -192 563
-203 577 -11 14 -143 17 -152 2z m115 -236 c22 -65 40 -122 40 -126 0 -4 -39
-8 -86 -8 -65 0 -85 3 -81 13 2 6 21 65 41 131 21 65 39 116 42 113 2 -2 22
-57 44 -123z"/>
<path d="M2287 2504 c-4 -4 -7 -139 -7 -301 l0 -293 166 0 165 0 -3 43 -3 42
-102 3 -103 3 -2 252 -3 252 -50 3 c-28 2 -54 0 -58 -4z"/>
<path d="M3275 2500 c-5 -8 -183 -514 -199 -567 -7 -22 -5 -23 53 -23 l59 0
22 65 22 66 116 -3 116 -3 20 -62 20 -63 63 0 c38 0 63 4 63 11 0 17 -192 563
-203 577 -11 14 -143 17 -152 2z m115 -236 c22 -65 40 -122 40 -126 0 -4 -39
-8 -86 -8 -71 0 -85 3 -81 15 4 8 22 66 42 130 20 64 39 115 41 112 2 -2 22
-57 44 -123z"/>
<path d="M2070 1695 c0 -8 -4 -15 -10 -15 -6 0 -10 -35 -10 -85 l0 -85 45 0
c67 0 95 31 95 105 0 69 -21 95 -80 95 -29 0 -40 -4 -40 -15z m74 -14 c3 -4
-6 -6 -19 -3 -14 2 -25 6 -25 8 0 8 39 4 44 -5z m0 -47 c9 -8 16 -19 16 -23 0
-13 -24 -31 -42 -31 -14 0 -18 8 -18 35 0 39 17 46 44 19z m-4 -84 c-20 -13
-60 -13 -60 0 0 6 17 10 38 10 27 0 33 -3 22 -10z"/>
<path d="M2227 1635 c-14 -41 -22 -75 -16 -75 5 0 7 -11 3 -25 -5 -19 -2 -25
10 -25 9 0 16 8 16 20 0 17 7 20 39 20 30 0 40 -4 44 -20 5 -18 38 -30 36 -12
0 4 -15 48 -33 97 -24 69 -37 91 -52 93 -17 2 -24 -10 -47 -73z m63 -10 c0 -8
-4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m3 -42
c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z"/>
<path d="M2360 1665 c0 -9 9 -15 25 -15 24 0 25 -2 25 -70 0 -56 3 -70 15 -70
12 0 15 14 15 70 0 68 1 70 25 70 16 0 25 6 25 15 0 12 -14 15 -65 15 -51 0
-65 -3 -65 -15z"/>
<path d="M3035 1657 c-20 -32 -10 -54 32 -72 44 -19 38 -40 -11 -39 -29 1 -36
-2 -34 -15 5 -24 60 -26 88 -3 31 25 22 65 -20 82 -41 17 -41 44 0 38 24 -4
30 -1 28 11 -2 10 -16 17 -35 19 -25 2 -35 -2 -48 -21z"/>
<path d="M3160 1595 l0 -85 50 0 c38 0 50 4 50 15 0 10 -11 15 -35 15 -31 0
-35 3 -35 25 0 21 5 25 30 25 20 0 30 5 30 15 0 10 -10 15 -30 15 -20 0 -30 5
-30 15 0 10 11 15 35 15 24 0 35 5 35 15 0 11 -12 15 -50 15 l-50 0 0 -85z"/>
<path d="M3290 1595 c0 -69 3 -85 15 -85 10 0 15 11 15 35 0 25 5 35 15 35 9
0 23 -16 32 -35 9 -19 23 -35 30 -35 16 0 16 3 -2 47 -11 25 -12 40 -5 47 17
17 11 55 -9 66 -11 5 -36 10 -55 10 l-36 0 0 -85z m78 38 c-2 -10 -13 -19 -26
-21 -17 -3 -22 2 -22 17 0 16 6 21 26 21 19 0 25 -5 22 -17z"/>
<path d="M3430 1673 c0 -5 12 -43 26 -86 22 -67 29 -78 47 -75 15 2 26 20 44
72 26 78 28 99 11 94 -6 -2 -22 -33 -34 -69 -21 -61 -22 -63 -32 -38 -6 15
-16 45 -22 68 -6 24 -17 41 -25 41 -8 0 -15 -3 -15 -7z"/>
<path d="M3600 1595 c0 -69 3 -85 15 -85 12 0 15 16 15 85 0 69 -3 85 -15 85
-12 0 -15 -16 -15 -85z"/>
<path d="M3702 1674 c-7 -3 -19 -19 -28 -36 -20 -39 -11 -92 19 -113 27 -19
71 -19 87 0 13 16 9 18 -28 15 -33 -2 -52 18 -52 55 0 39 21 59 55 52 20 -3
25 -1 23 12 -3 16 -50 25 -76 15z"/>
<path d="M3810 1595 l0 -85 50 0 c38 0 50 4 50 15 0 10 -11 15 -35 15 -31 0
-35 3 -35 25 0 21 5 25 30 25 20 0 30 5 30 15 0 10 -10 15 -30 15 -20 0 -30 5
-30 15 0 10 11 15 35 15 24 0 35 5 35 15 0 11 -12 15 -50 15 l-50 0 0 -85z"/>
<path d="M3953 1668 c-30 -38 -24 -57 25 -82 42 -22 36 -41 -12 -40 -29 1 -36
-2 -34 -15 2 -10 15 -17 39 -19 71 -6 94 71 29 98 -41 17 -41 44 0 38 24 -4
30 -1 28 11 -4 20 -61 27 -75 9z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
